<template>
    <div class="policy">
        <p>Gizlilik Politikası</p>
<p>Son güncelleme: 21 Ekim 2024</p>
<p>Gizlilik Politikasına Genel Bakış</p>
<p>
  Hizmetimize hoş geldiniz, hizmet SMART-PLUS PTE. LTD. (bundan sonra
  'SMART-PLUS', 'biz' veya 'biz' olarak anılacaktır) tarafından sağlanmakta ve
  kontrol edilmektedir. Hizmeti kullanarak, bu Gizlilik Politikasını kabul etmiş
  olursunuz.
</p>
<p>Hizmet şunları içerir:</p>
<p>
   *cloud.akuvox.com, *cloud.akubela.com (*cloud, ecloud, ucloud, jcloud,
  scloud vb. içerir) adreslerinden erişilebilen web sitemiz ve kullanıcı
  hesaplarımız ve üçüncü taraf siteleri hariç olmak üzere bunlarla bağlantılı
  tüm ilişkili siteler ('Portal');
</p>
<p>
   Web Sitesi aracılığıyla erişilebilen çevrimiçi hizmetler ('Web Hizmetleri');
</p>
<p>
   Akuvox SmartPlus, AK-Partner, BelaHome vb. gibi bir mobil cihaza
  indirilebilen yazılım uygulamaları ('Mobil Uygulamalar');
</p>
<p>
   Kapı telefonları, iç mekan monitörleri, android görüntülü telefonlar, erişim
  kontrol terminalleri ve ev kontrol panelleri dahil ancak bunlarla sınırlı
  olmamak üzere hizmetin ilgili ürünleri ('Cihazlar');
</p>
<p>
   Web Sitesi ve Mobil Uygulamalarda bulunan tüm hizmetler ('Kullanılabilir
  Hizmetler').
</p>
<p>
   'HİZMET' terimi Web Sitesi, Web Hizmetleri, Mobil Uygulamalar, Cihazlar ve
  Kullanılabilir Hizmetler anlamına gelir.
</p>
<p>
  Gizlilik politikası, kim olduğumuzu, sizin hakkınızda hangi bilgileri
  topladığımızı, kişisel bilgilerinizi nasıl kullandığımızı ve ifşa ettiğimizi,
  bilgilerinizi nasıl koruduğumuza ilişkin ayrıntıları ve veri gizliliği
  haklarınızı açıklar. HİZMETİMİZ kişisel bilgilerinizi çok ciddiye alır ve size
  kişisel bilgileriniz üzerinde kontrol sağlar. Kişisel verilerinizi ve
  gizliliğinizi korumak için sağlam şifreleme teknolojisi kullanırız ve tüm
  verileri yönetmek için katı politikalar uygularız. Sizi ilgilendiren kişisel
  verileri her zaman Genel Veri Koruma Yönetmeliği ("GDPR") ve Kaliforniya
  Tüketici Gizlilik Yasası ("CCPA") uyarınca koruyacağız.
</p>
<p>A. Biz kimiz</p>
<p>
  HİZMET, SMART-PLUS PTE. LTD. a.s. tarafından sağlanır ve kontrol edilir. Vergi
  Kimlik Numarası 202116626G ve kayıtlı ofisi 6 RAFFLES QUAY #14-06, Singapur
  (048580) adresinde bulunan.
</p>
<p>B. Hangi Kişisel Bilgileri Topluyoruz</p>
<p>
  Kişisel bilgiler, HİZMETİMİZ tarafından geçerli yasalar uyarınca hizmet
  sağlama amacıyla toplanır.
</p>
<p>
  "Kişisel bilgiler", "kişisel veriler" ile aynı anlama gelir ve tanımlanmış
  veya tanımlanabilir bir gerçek kişiyle ("veri sahibi") ilgili herhangi bir
  bilgiyi içerir; tanımlanabilir kişi, özellikle bir kimlik numarasına veya
  fiziksel, fizyolojik, zihinsel, ekonomik, kültürel veya sosyal kimliğine özgü
  bir veya daha fazla faktöre atıfta bulunularak doğrudan veya dolaylı olarak
  tanımlanabilen kişidir.
</p>
<p>
  HİZMETİ size sunmak için gerekli olan kişisel bilgileri sağlamanızı
  isteyeceğiz. Bu bilgilerin herhangi birini veya tamamını bize sağlamazsanız,
  bunları işlemeyeceğiz, ancak HİZMET veya bazı işlevleri sınırlı olabilir.
</p>
<p>
  Aşağıdaki bölümde, HİZMETİMİZİ kullandığınızda toplayacağımız bilgi türlerini
  anlamanızı istiyoruz.
</p>
<p>1. Kullanıcı Hesabı Kaydı</p>
<p>
  SERVİS hesabınızı oluşturmak için, kayıt işlemi sırasında aşağıdaki Kişisel
  Bilgileri sağlamanız gerekir:
</p>
<p>Mülk Yöneticisi Hesabı：</p>
<p> Ad</p>
<p> Soyad</p>
<p> E-posta adresi</p>
<p>Son Kullanıcı Hesabı：</p>
<p> Ad</p>
<p> Soyad</p>
<p> E-posta adresi</p>
<p> Cep telefonu numarası</p>
<p>
   Adres (Eğer konut kullanıcısıysanız, konut adresinizi toplayacağız; eğer
  işletme kullanıcısıysanız, işletme adresinizi toplayacağız.)
</p>
<p>2. HİZMETİN Kullanımı</p>
<p>
  HİZMETİMİZİ kullanmanız sırasında, aşağıdaki Kişisel Bilgileri (veya sadece
  bazılarını) toplayacağız.  Cep telefonu IP adresi
</p>
<p> Cep telefonu Bluetooth bilgileri</p>
<p> Cihaz adı</p>
<p> Cihaz IP adresi ve Mac adresi</p>
<p> Cihaz ve cep telefonu konumu</p>
<p> Kimlik doğrulama bilgileri</p>
<p>
   Mobil Uygulamalar aracılığıyla işlenen ve bulutta depolanan görüntüler,
  videolar ve sesler
</p>
<p> Cihazların ve sistemlerin yapılandırma değişiklikleri</p>
<p>
   Özellikle arama günlükleri, kapı açılış anlık görüntüleri, kapı açma
  geçmişleri ve otomasyon geçmişleri olmak üzere günlük bilgileri
</p>
<p> Ödeme durumu</p>
<p></p>
<p>3. Sorun Giderme ve İyileştirme Hizmeti</p>
<p>
  HİZMET'i kullanırken karşılaştığınız sorunları çözmek ve HİZMET'imizi optimize
  etmek için Mobil Uygulamalarımızı kullanırken aşağıdaki bilgileri
  toplayacağız.
</p>
<p> Son oturum açma</p>
<p> Kullanım süresi</p>
<p> Sesli ve görüntülü aramalar</p>
<p> Sistem günlükleri</p>
<p> Yüklü uygulamalar Veriler</p>
<p>
   Kişisel bilgiler içermeleri durumunda yapılandırmaların indirilmesi, örn.
  iletişim adları
</p>
<p>4. HİZMET aboneliği</p>
<p>
  HİZMET için ödeme yapmayı seçtiyseniz, aşağıdaki kişisel bilgiler bize
  sağlanacaktır.
</p>
<p> Ad</p>
<p> Soyad</p>
<p> E-posta adresi</p>
<p> İkamet ülkesi</p>
<p> Ödeme bilgileri</p>
<p>
  Bu kişisel verilerin işlenmesi, size HİZMET'i sunmak için gereklidir ve
  sizinle yaptığımız sözleşmenin yürütülmesi için gereklidir.
</p>
<p>5.Üçüncü Taraf Hizmetleri</p>
<p>
  HİZMETİMİZ aracılığıyla bir üçüncü taraf hizmeti kullandığınızda,
  isteklerinize yanıt vermek, size talep edilen hizmeti sağlamak ve HİZMETİMİZİ
  iyileştirmek için kişisel bilgileri işleriz. HİZMET'in sağlanmasının bir
  parçası olarak ve size özel içerik sağlamak için, bu üçüncü taraf
  hizmetleriyle yaptığımız anlaşmalar aracılığıyla kullanıcıları tanımlamak ve
  yönetmek için kişisel bilgiler alacağız.
</p>
<p>
  Size sosyal paylaşım özelliğini sunmak için, sistem bilgileri, ağ durumu,
  IMEI, OAID, IMSI, uygulama listesi, cep telefonu baz istasyonu, sosyal
  platform OpenID ve cep telefonunuzun coğrafi konumu gibi kişisel bilgilerinizi
  toplayacak olan MobTech ShareSDK ile entegre olduk. Ayrıntılar için lütfen
  ilgili gizlilik politikaları ve şartları için www.mob.com/about/policy
  adresini ziyaret edin.
</p>
<p>
  Lütfen üçüncü taraf hizmetlerini kullanımınızın bu üçüncü taraf kullanım veya
  hizmet şartlarına ve ilgili gizlilik bildirimlerine tabi olduğunu unutmayın.
  Cihazlarınızda etkinleştirmeden önce bu üçüncü taraf şartlarını dikkatlice
  okumanızı öneririz. Kişisel bilgilerinizi işlememiz, sizinle olan
  sözleşmemizin ifası ve meşru menfaatimize hizmet etmek için gereklidir.
</p>
<p>
  Ayrıca, üçüncü taraf hizmetlerinden de sizin hakkınızda bilgi toplayabiliriz.
  Bu kişisel verilerin işlenmesi, size HİZMETİ sunmak için gereklidir ve bu
  nedenle sizinle olan sözleşmemizin ifası için gereklidir.
</p>
<p>
  Önemli: Bu veri toplanmasına istediğiniz zaman itiraz edebilirsiniz (herhangi
  bir nedenle veya kolaylık için). Bu durumda, bu amaçla kişisel veri toplamayı
  derhal durduracağız.
</p>
<p>C. Çerezler ve Benzer Teknolojiler</p>
<p>
  Çerezler ve benzer teknolojiler, internette yaygın olarak kullanılan
  teknolojilerdir. Bir web sitesini ziyaret ettiğinizde bilgisayarınıza
  gönderilen karakter dizileri içeren küçük dosyalardır. Bir web sitesini tekrar
  ziyaret ettiğinizde, çerezler web sitesinin tarayıcınızı tanımasını sağlar.
  Çerezler ayrıca kullanıcı tercihlerini ve diğer bilgileri de depolayabilir.
  Tarayıcınızı tüm çerezleri reddedecek veya bir web sitesi bilgisayarınıza
  çerez gönderdiğinde sizi uyaracak şekilde sıfırlayabilirsiniz. Ancak çerezler
  olmadan, bazı web sitesi özellikleri veya hizmetleri düzgün çalışmayabilir.
</p>
<p>
  Web Sitesine ve Mobil Uygulamalara eriştiğinizde ve bunları kullandığınızda,
  bilgi toplamak ve depolamak için cihazınıza çerezler veya anonim
  tanımlayıcılar göndermek için bu teknolojileri kullanabiliriz. Yerel
  yasalarınız İnternet Protokolü (IP) adreslerini veya benzer tanımlayıcı
  işaretleri kişisel bilgi olarak ele almadığı sürece, çerezler ve diğer
  teknolojiler aracılığıyla toplanan bilgileri kişisel olmayan bilgiler olarak
  ele alırız. Aşağıdaki hizmetler için çerezler ve benzer teknolojiler
  kullanırız:
</p>
<p>1. HİZMETİMİZİN güvenli ve verimli bir şekilde çalışmasını sağlamak için</p>
<p>
  Hizmete güvenli bir şekilde giriş yaptığınızı doğrulamamıza veya
  dolandırıcılık gibi yasa dışı eylemlerle karşılaşıp karşılaşmayacağınızı
  değerlendirmemize yardımcı olan kimlik doğrulama veya güvenlik çerezleri veya
  anonim tanımlayıcılar ayarlayabiliriz. Bu teknolojiler ayrıca hizmet
  verimliliğini ve sistem oturum açma ve yanıt hızını iyileştirmemize yardımcı
  olacaktır.
</p>
<p>2. Daha kolay bir erişim deneyimi elde etmenize yardımcı olmak için</p>
<p>
  Bu tür teknolojileri kullanmak, tek tıklamayla oturum açma ve arama geçmişi
  gibi yöntemlerle kişisel bilgileri tekrar tekrar doldurma ve arama içeriği
  girme adımlarından ve süreçlerinden kaçınmanıza yardımcı olabilir.
</p>
<p>3. Günlük dosyası için</p>
<p>
  Çoğu web sitesi gibi, belirli bilgileri toplar ve günlük dosyalarına
  kaydederiz. Bu tür bilgiler İnternet Protokolü (IP) adresleri, tarayıcı
  türleri, İnternet servis sağlayıcıları (İSS'ler), yönlendiren/çıkış sayfaları,
  işletim sistemleri, tarih/zaman damgaları ve/veya tıklama akışı verilerini
  içerebilir. Otomatik olarak toplanan günlük verilerini topladığımız diğer
  bilgilerle ilişkilendirmeyiz.
</p>
<p>4. Mobil analiz için</p>
<p>
  Web Sitesi ve Mobil Uygulamalar için, mobil yazılımımızın telefonunuzdaki
  işlevselliğini daha iyi anlamak için mobil analiz yazılımı kullanırız. Bu
  yazılım, uygulamayı ne sıklıkta kullandığınız, uygulama içinde gerçekleşen
  olaylar, kümülatif kullanım, performans verileri ve uygulama çökmesinin nerede
  meydana geldiğine dair veriler gibi bilgileri kaydedebilir. Analiz yazılımında
  saklanan bilgileri Web Sitesi ve Mobil Uygulamalara gönderdiğiniz hiçbir
  kişisel bilgiyle ilişkilendirmeyiz.
</p>
<p>D. Kişisel Bilgilerinizi Nasıl Kullanırız</p>
<p>Topladığımız kişisel bilgileri aşağıdaki amaçlarla kullanabiliriz:</p>
<p>
  1. Size hizmeti sunmak için. Kişisel bilgilerinizi, HİZMETİ kullanmanızı
  sağlamak için kullanırız, örneğin ziyaretçilerin sizi interkom aracılığıyla
  aramasına izin veririz. 2. Sorun giderme ve bakım yapmak için. Uygulamanızla
  ilgili sorunları analiz etmek ve teşhis etmek, hizmet deneyimini iyileştirmek
  için kullanıcının yüklü uygulama verilerini https://maintenance.akuvox.com
  adresine yüklüyoruz.
</p>
<p>
  3. Kişisel bilgilerinizi pazarlama amaçları için sizinle iletişim kurmak için
  kullanabiliriz, örneğin hizmet anketleri yapmak, sorularınıza yanıt vermek ve
  yeni hizmetleri tanıtmak.
</p>
<p>
  4. HİZMETİ iyileştirmek için Web Sitesi ve Mobil Uygulamaları kullanımınızla
  ilgili istatistiksel bilgilerin veri analizi, araştırması ve geliştirilmesi
  gibi dahili kullanım için;
</p>
<p>
  5. HİZMETİMİZİN paylaşım işlevselliğini geliştirmek için cihazınıza yüklü
  uygulamalar hakkında bilgi toplayabiliriz. Bu bilgiler yalnızca bu uygulamalar
  aracılığıyla içerik paylaşımını etkinleştirmek ve size daha kolay paylaşım
  seçenekleri sunmak için kullanılır.
</p>
<p>6. Yazılım doğrulaması ve hizmet yükseltmeleri gerçekleştirmek.</p>
<p>
  7. Yasalara uymak ve haklarımızı kullanmak. Kişisel bilgilerinizi, geçerli
  yasalara uyumu değerlendirmek ve sağlamak, varlıklarımızı korumak veya
  yasadışılık veya haksızlık iddialarını araştırmak veya bunlara karşı savunmak
  (yasal tavsiye almak veya yasal hakları tesis etmek, kullanmak veya savunmak
  dahil) ve mahkeme emirlerine veya yargı veya diğer hükümet celplerine veya
  arama emirlerine yanıt vermek için makul ölçüde gerekli olduğu durumlarda
  kullanabiliriz.
</p>
<p>E. Kişisel Bilgilerinizi Nasıl Açıklıyoruz</p>
<p>
  Kişisel bilgileri herhangi bir amaçla kimseye satmıyoruz. Kişisel
  bilgilerinizi aşağıdaki alıcı kategorilerine açıklayabiliriz:
</p>
<p>1. Hizmet Sağlayıcılar</p>
<p>
  Bilgilerinizi, yerel pazarlarınızdaki iş operasyonlarımızı desteklemek için
  hizmet sağlayan üçüncü taraf hizmet sağlayıcılara açıklayabiliriz. Bunlara ev
  sahipleri, mülk yöneticileri ve yerel iş ortaklarımız dahil olabilir.
</p>
<p>2. Kolluk Kuvvetleri</p>
<p>
  Yasal olarak gerekli olması durumunda veya bu tür bir kullanımın yasal
  yükümlülüğe, işleme veya talebe uymak için makul ölçüde gerekli olması
  durumunda bilgilerinizi kolluk kuvvetleri, kamu otoriteleri veya diğer
  kuruluşlarla paylaşacağız. Veri iletim süreci sırasında, bilgilerinizin
  güvenliğini sağlamak için verileri şifreleyeceğiz.
</p>
<p>F. Kişisel veri depolama ve Kişisel veri aktarımı</p>
<p>
  SERVİS'in altyapıları, tüm kişisel verilerinizin ilgili sunucularda
  depolandığı Kuzey Kaliforniya, ABD, Frankfurt Almanya ve Singapur gibi çeşitli
  yerlerde bulunan Web Hizmetleri üzerinde çalışmaktadır. Kişisel verileriniz
  sunucudan Amazon Cloud veya Alibaba Cloud'a yedeklenecektir. Veri aktarım
  süreci sırasında verilerinizin güvenliğini sağlamak için verilerinizi
  şifreleyecek ve koruyacağız.
</p>
<p>
  Bu Gizlilik Politikasında açıklanan amaçlar doğrultusunda bu bilgileri
  işlerken, size talep edilen hizmetleri sağlamak için bilgilerinizin belirli
  bir bölümüne erişebiliriz. Kurumsal içerik veri yönetimi kurallarımız, tüm
  veri aktarımlarının geçerli yerel yasalara ve düzenlemelere uygun olmasını
  sağlayacaktır.
</p>
<p>G. Haklarınız ve Seçimleriniz</p>
<p>
  HİZMETİMİZİ kullanırken kişisel verilerinizle ilgili aşağıdaki haklara
  sahipsiniz.
</p>
<p>Erişim hakkı</p>
<p>
  Kişisel verilerinizin işlenip işlenmediğine dair onay alma hakkına sahipsiniz.
  Ve verilerinizi işlersek, kişisel verilere ve aşağıdaki bilgilere erişme
  hakkına sahipsiniz:
</p>
<p>a) işleme amaçları;</p>
<p>b) ilgili kişisel verilerin kategorileri;</p>
<p>
  c) kişisel verilerin açıklandığı veya açıklanacağı alıcılar veya alıcı
  kategorileri, özellikle üçüncü ülkelerdeki veya uluslararası kuruluşlardaki
  alıcılar;
</p>
<p>
  d) mümkünse, kişisel verilerin saklanacağı öngörülen süre veya mümkün değilse,
  bu süreyi belirlemek için kullanılan kriterler;
</p>
<p>
  e) denetleyiciden kişisel verilerin düzeltilmesini veya silinmesini veya
  sizinle ilgili kişisel verilerin işlenmesinin kısıtlanmasını talep etme veya
  bu tür işleme itiraz etme hakkının varlığı;
</p>
<p>f) bir denetim makamına şikayette bulunma hakkı;</p>
<p>
  g) kişisel veriler sizden toplanmadığında, kaynaklarına ilişkin mevcut
  bilgiler;
</p>
<p>
  h) GDPR'nin 22(1) ve (4) maddelerinde belirtilen profilleme dahil olmak üzere
  otomatik karar almanın varlığı ve en azından bu durumlarda, söz konusu mantık
  hakkında anlamlı bilgiler ve bu tür işlemlerin sizin için önemi ve öngörülen
  sonuçları
</p>
<p>
  Kişisel veriler üçüncü bir ülkeye veya uluslararası bir kuruluşa
  aktarıldığında, aktarımla ilgili uygun güvenlik önlemleri hakkında
  bilgilendirilme hakkınız vardır.
</p>
<p>Düzeltme hakkı</p>
<p>
  Bizden, sizi ilgilendiren yanlış kişisel verilerin düzeltilmesini
  gecikmeksizin talep etme hakkına sahipsiniz. İşlemenin amaçları göz önünde
  bulundurulduğunda, ek bir beyanda bulunmak da dahil olmak üzere, eksik kişisel
  verilerin tamamlanmasını talep etme hakkına sahipsiniz.
</p>
<p>Silme hakkı ("unutulma hakkı")</p>
<p>
  Aşağıdaki koşullardan en az biri karşılanıyorsa, sizi ilgilendiren kişisel
  verilerin silinmesini gecikmeksizin talep etme hakkına sahipsiniz:
</p>
<p>
  a) kişisel verileriniz, toplandıkları veya başka şekilde işlendikleri
  amaçlarla bağlantılı olarak artık gerekli değildir;
</p>
<p>
  b) kişisel verileriniz sizin onayınız temelinde işlendi ve siz bu onayı geri
  çektiniz ve işlemeye devam etmek için başka bir yasal dayanağımız yok;
</p>
<p>
  c) işleme itiraz ediyorsunuz ve işleme için geçerli bir meşru gerekçe
  bulunmuyor (örneğin, pazarlama amaçlarıyla sizinle ilgili kişisel verilerin
  işlenmesine itiraz edebilirsiniz);
</p>
<p>d) kişisel verileriniz hukuka aykırı olarak işlendi;</p>
<p>
  e) kişisel verileriniz, tabi olduğumuz yasal bir yükümlülüğe uymak için
  silinmelidir.
</p>
<p>
  Sizinle ilgili kişisel verileri silmemizi istiyorsanız ve yukarıda belirtilen
  koşullardan en az birinin geçerli olup olmadığından veya herhangi bir
  sınırlama olup olmadığından emin değilseniz, bizimle iletişime geçebilirsiniz
  ve talebinizi mümkün olan en iyi şekilde çözmek için size yardımcı olacağız.
</p>
<p>İşlemenin kısıtlanması hakkı</p>
<p>Aşağıdakilerden biri geçerliyse, işlemenin kısıtlanması hakkınız vardır:</p>
<p>a) kişisel verilerin doğruluğuna itiraz etmeniz;</p>
<p>
  b) işleme hukuka aykırıdır ve kişisel verilerin silinmesine karşı çıkıyor ve
  bunun yerine kullanımının kısıtlanmasını talep ediyorsunuz;
</p>
<p>
  c) artık işleme amaçları için kişisel verilere ihtiyacımız yok, ancak yasal
  iddiaların kurulması, uygulanması veya savunulması için sizin tarafınızdan
  gerekli;
</p>
<p>
  d) işleme itiraz ediyorsunuz ve itirazınız şu anda meşru gerekçelerimizin
  sizinkileri geçersiz kılıp kılmadığının doğrulanmasını bekliyor.
</p>
<p>
  Depolama hariç olmak üzere işleme kısıtlandığında, ilgili kişisel veriler
  yalnızca sizin izninizle veya yasal iddiaların kurulması, uygulanması veya
  savunulması veya başka bir gerçek veya tüzel kişinin haklarının korunması için
  işlenecektir.
</p>
<p>Veri taşınabilirliği hakkı</p>
<p>
  Sizinle ilgili kişisel verilerin bir kısmını yapılandırılmış, yaygın olarak
  kullanılan ve makine tarafından okunabilir bir biçimde alma ve bu verileri
  engelsiz bir şekilde başka bir denetleyiciye iletme hakkına sahipsiniz. Bu
  hak, sizinle ilgili şu kişisel veriler için geçerlidir:
</p>
<p>a) Sizden elde ettiğimiz,</p>
<p>b) Sizin izninize veya bir sözleşmeye dayanarak işlenen ve</p>
<p>c) Otomatik yollarla işlenen</p>
<p>
  Yukarıdaki kriterleri karşılayan kişisel verileri işlersek, teknik olarak
  mümkün olduğu takdirde, bu tür kişisel verilerin tarafımızca doğrudan başka
  bir denetleyiciye iletilmesi hakkına da sahipsiniz.
</p>
<p>İtiraz etme hakkı</p>
<p>
  Örneğin doğrudan pazarlama amaçları için, sizinle ilgili kişisel verilerin
  işlenmesine itiraz etme hakkına sahipsiniz. Her bir itirazınızı dikkatlice
  değerlendireceğiz ve işleme için zorlayıcı meşru gerekçeler yoksa kişisel
  verileri işlemeyi durduracağız.
</p>
<p>H. Kişisel verilerinizin güvenliği</p>
<p>
  HİZMETİMİZ sağlam yerleşik güvenlik özelliklerine sahiptir. Sıkı bir bilgi
  yönetimi politikası geliştiriyoruz, profesyonel bir teknik ekiple donatılmış
  durumdayız ve kişisel bilgilerinizi yetkisiz tarama, ifşa, kötüye kullanım,
  değişiklik, imha ve kayıptan korumak için bir dizi makul önlem alıyoruz,
  bunlar şunları içerir:
</p>
<p>
  1. Verilerinizin iletim sırasında gizli kalmasını sağlamak için şifreleme
  teknolojisini kullanın;
</p>
<p>
  2. Hesap güvenliğinizi korumanıza yardımcı olmak için birden fazla güvenlik
  özelliği sağlayın;
</p>
<p>
  3. Yetkisiz kişilerin sistemlerimize erişmesini önlemek için fiziksel güvenlik
  önlemleri dahil olmak üzere bilgi toplama, depolama ve işleme uygulamalarımızı
  inceleyin.
</p>
<p>I. Kişisel verilerinizi ne kadar süreyle saklıyoruz</p>
<p>
  Topladığımız kişisel verileri, ne olduklarına ve nasıl kullandığımıza bağlı
  olarak farklı süreler boyunca saklıyoruz. Neden farklı türdeki veri kümelerini
  farklı süreler boyunca tuttuğumuzu açıklayacağız.
</p>
<p>
  1. Toplama nedenine göre farklı veri türleri için farklı saklama süreleri
  belirliyoruz, örneğin kapı serbest bırakma günlüğü ve arama geçmişi, 1 ay
  sonra veriler otomatik olarak silinecektir. 2. Hesabınız silinene kadar
  saklanan Kişisel Bilgileriniz, örneğin kullanıcı hesabı bilgileri.
</p>
<p>
  3. Sınırlı amaçlar için uzun süreler saklanan bilgiler. Bazen ticari, yasal
  veya düzenleyici gereklilikler, belirli bilgileri belirli amaçlar için uzun
  süre saklamamızı zorunlu kılar.
</p>
<p>J. Çocukların gizliliği</p>
<p>
  HİZMETLERİMİZİN 18 yaşından küçük çocuklar tarafından erişilmesini veya
  kullanılmasını AMAÇLAMIYORUZ. 18 yaşından küçükseniz, Hizmetlerimizi yalnızca
  ebeveyninizin veya yasal vasinizin katılımıyla kullanabilirsiniz. Bir hesabın
  18 yaşından küçük kayıtlı bir kullanıcıyla ilişkili olduğuna dair gerçek bir
  bilgi edinirsek, söz konusu hesapla ilişkili bilgileri derhal sileriz. Ebeveyn
  veya veliyseniz ve çocuğunuzun izniniz olmadan bize kişisel bilgiler verdiğini
  keşfederseniz, lütfen bilgileri sistemlerimizden silmemizi talep etmek için
  lütfen support@akuvoxsmartplus.com adresine başvurun.
</p>
<p>K. Bu gizlilik politikasındaki güncellemeler</p>
<p>
  Bu gizlilik politikasını zaman zaman değiştirebilir ve güncelleyebiliriz.
  Gizlilik politikasıyla ilgili güncellemeleri bildirimler ve diğer formlar
  aracılığıyla Web Sitesi ve Mobil Uygulamalara göndereceğiz. Gizlilik
  politikasının en son sürümünü kontrol etmek için Mobil Uygulamalarımızı
  ziyaret edebilirsiniz. Size önemli değişikliklerle ilgili resmi bildirimler
  göndereceğiz (belirli hizmetler için gizlilik politikamızdaki değişikliklere
  ilişkin e-posta bildirimleri dahil).
</p>
<p>L. Bizimle iletişime geçin</p>
<p>
  Kişisel verilerinizin kullanımımız veya ilgili haklarınızın kullanımıyla
  ilgili başka sorularınız veya gereksinimleriniz varsa veya daha fazla bilgiye
  ihtiyacınız varsa, support@akuvoxsmartplus.com adresinden bizimle iletişime
  geçebilirsiniz. Lütfen adınızı (veya takma adınızı), tercih ettiğiniz iletişim
  yöntemini, isteğinizin veya yorumunuzun ayrıntılarını ve ilgili web sitesi
  bağlantılarını belirtin.
</p>

        <br />
        <br />
        <br />
        <br />
        <br />
    </div>
</template>

<style lang="scss" scoped>
p {
    margin-top: 10px;
}

.policy-ul {
    left: 20px;
    position: relative;
    width: 90%;

    li {
        list-style: disc;
    }
}

h3 {
    margin-top: 10px;
}
</style>
